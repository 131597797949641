import React, { useEffect, useState } from 'react'
import FocusTrap from 'focus-trap-react'
import { m } from 'framer-motion'
import cx from 'classnames'

import { useSiteContext, useAddItem, useToggleFrames } from '@lib/context'
import { ProductPrice } from './product'
import Icon from './icon'
import {
  buildFrameSrc,
  getVariantTitle,
  useWindowSize,
  xsMax,
} from '@lib/helpers'
import Slider from '@components/slider'

const Frames = ({ data }) => {
  const { frames = [], frameTypes = [], disclaimers = {} } = data || {}
  const { isAdding, isFramesOpen, lastAddedPoster } = useSiteContext()
  const size = lastAddedPoster?.options?.find((o) => o.name === 'Size')
  if (!lastAddedPoster || !frames || !size) return null

  const addItemToCart = useAddItem()
  const toggleFrames = useToggleFrames()
  const { width: vw } = useWindowSize()
  const imgSize = vw * (vw <= xsMax ? 0.75 : 0.25)

  // Check that Shopify is connected

  const [isAddingFrame, setIsAddingFrame] = useState(false)
  const [hasFocus, setHasFocus] = useState(false)

  const getDimensions = (dimensions) => {
    if (dimensions.indexOf('x') > -1)
      return dimensions
        .split(' ')[0]
        .split('x')
        .map((n) => Number(n))
    return [dimensions, dimensions]
  }

  const getSortedDimensions = (dimensions) => {
    return getDimensions(dimensions).sort((a, b) => (a > b ? 1 : -1))
  }

  const isLargeFrame = () => {
    const [w, h] = getSortedDimensions(size.value)
    return Math.min(w, h) >= 70
  }

  const isClosestFrameSize = (variants, width) => {
    const [w] = getDimensions(size.value)
    const num = Number(width)
    const closest = variants
      .map((v) =>
        v.options.filter((o) => o.name === 'Size').map((o) => Number(o.value))
      )
      .flat()
      .reduce((prev, curr) =>
        Math.abs(curr - w) < Math.abs(prev - w) ? curr : prev
      )
    return w < num && num === closest
  }

  const isSizeEqual = (first, second) => {
    return getSortedDimensions(first).every(
      (d, i) => getSortedDimensions(second)[i] === d
    )
  }

  const isLandscape = () => {
    const [w, h] = getDimensions(size.value)
    return w > h
  }

  // All frames the same size as the poster
  const availableFrames = frames
    .filter((frame) => {
      const { frameType, variants } = frame

      // Check if poster is restricted to only some frames
      if (
        lastAddedPoster.restrictFrameTypes &&
        !lastAddedPoster.restrictFrameTypes.some(
          (ft) => ft.slug === frame.frameType.slug
        )
      )
        return false
      // Check if variants size match frame size
      return variants.some((variant) =>
        variant.options.some(({ value }) => {
          if (frameType?.portraitOnly) {
            if (isLandscape()) return false
            return isClosestFrameSize(variants, value)
          }
          return isSizeEqual(value, size.value)
        })
      )
    })
    .map((f) => ({
      ...f,
      order: Number.isInteger(f.order) ? f.order : frames.length * 2,
    }))
    .sort((a, b) => (a.order > b.order ? 1 : -1))

  // All frames the same size as the poster
  const availableFrameTypes = frameTypes.filter((frameType) => {
    // Checks if any of the available frames has the current frametype
    return availableFrames.some(
      (frame) => frameType.slug === frame.frameType.slug
    )
  })

  const [activeFrameSlug, setActiveFrameSlug] = useState()

  const addItem = (product, key) => {
    if (product.inStock && !isAdding && !isAddingFrame) {
      setIsAddingFrame(String(key))
      addItemToCart(product, 1)
    }
  }

  const handleKeyDown = (e) => {
    if (e.which === 27) toggleFrames(false)
  }

  useEffect(() => {
    if (isFramesOpen && availableFrameTypes?.length > 0)
      setActiveFrameSlug(availableFrameTypes[0]?.slug)
  }, [isFramesOpen])

  useEffect(() => {
    if (!isAdding && isAddingFrame) {
      toggleFrames(false)
      setIsAddingFrame(false)
    } else if (isAdding && !isAddingFrame && availableFrames.length !== 0) {
      // This bit makes sure that the snackbar is not overlayed
      // on top of the framepicker
      toggleFrames(true)
    }
  }, [isAdding, isAddingFrame])

  if (availableFrames.length === 0) return null

  return (
    <>
      <FocusTrap
        active={isFramesOpen && hasFocus}
        focusTrapOptions={{ allowOutsideClick: true }}
      >
        <m.div
          initial="hide"
          animate={isFramesOpen ? 'show' : 'hide'}
          variants={{
            show: {
              y: '0%',
            },
            hide: {
              y: '100%',
            },
          }}
          transition={{ duration: 0.6, ease: [0.16, 1, 0.3, 1] }}
          onKeyDown={(e) => handleKeyDown(e)}
          onAnimationComplete={(v) => setHasFocus(v === 'show')}
          className={cx('frames', {
            'is-active': isFramesOpen,
            'is-adding': isAdding || isAddingFrame,
          })}
        >
          <m.div
            initial="hide"
            animate={isFramesOpen ? 'show' : 'hide'}
            variants={{
              show: {
                opacity: 1,
              },
              hide: {
                opacity: 0,
              },
            }}
            transition={{ duration: 0.6, ease: [0.16, 1, 0.3, 1] }}
            className="frames--title"
          >
            Add frame?
          </m.div>
          <div className="frames--inner">
            <div className="frames--header space-x-2">
              {availableFrameTypes.map((frameType, key) => (
                <button
                  key={key}
                  className={cx('btn', {
                    'is-primary': activeFrameSlug === frameType.slug,
                  })}
                  onClick={() => setActiveFrameSlug(frameType.slug)}
                >
                  {frameType.title}
                </button>
              ))}
            </div>

            <Slider
              className={cx('frames--content product-slider', {
                'is-landscape': isLandscape(),
              })}
            >
              <div className="frames--item" onClick={() => toggleFrames(false)}>
                <p>
                  No frame this time
                  <br />
                  Art print only
                </p>
                <div className="frames--item--image">
                  {lastAddedPoster?.photo && (
                    <img
                      loading='lazy'
                      src={lastAddedPoster.photo}
                      className="frames--item--poster has-dropshadow"
                    />
                  )}
                  <div className="pill items-center">
                    <span>No frame </span>
                    <Icon name="BasketSolid" color="white" className="w-3" />
                  </div>
                </div>
              </div>
              {availableFrames
                .filter(({ frameType }) => frameType.slug === activeFrameSlug)
                .map((frame, key) => {
                  const variant = frame.variants.find((variant) => {
                    // Find correct frame for magnetic stiicks
                    if (frame.frameType?.portraitOnly)
                      return variant.options.find((o) =>
                        isClosestFrameSize(frame.variants, o.value)
                      )
                    // Find correct frame for all other frames
                    return variant.options.find((o) =>
                      isSizeEqual(o.value, size.value)
                    )
                  })
                  if (!variant) return null

                  return (
                    <div
                      key={frame.id}
                      className={cx('frames--item', {
                        'is-adding': isAddingFrame,
                        'is-soldout': !variant.inStock,
                      })}
                      onClick={() => addItem(variant, key)}
                    >
                      <p className="frames--item--title">
                        {frame.title}
                        <br />
                        {getVariantTitle(variant)}
                      </p>
                      <div className="frames--item--image">
                        {lastAddedPoster?.photo &&
                          !frame.frameType.hidePoster && (
                            <img
                              loading='lazy'
                              src={lastAddedPoster.photo}
                              className="frames--item--poster"
                            />
                          )}
                        {frame.altPhotos && (
                          <div className="frames--item--frame">
                            <img
                              loading='lazy'
                              src={buildFrameSrc(frame.altPhotos, {
                                width: imgSize,
                                height: imgSize,
                              })}
                            />
                          </div>
                        )}
                        <div
                          className={cx('pill items-center', {
                            'is-disabled': !variant.inStock,
                          })}
                        >
                          <span className="pill--hover">ADD</span>
                          <ProductPrice item={variant} />
                          <Icon
                            name="BasketSolid"
                            className="w-3"
                            color="white"
                          />
                        </div>
                      </div>
                    </div>
                  )
                })}
            </Slider>
            {isLargeFrame() && disclaimers?.noWorldwideShippingText && (
              <p className="mt-3">{disclaimers.noWorldwideShippingText}</p>
            )}
          </div>
        </m.div>
      </FocusTrap>

      <div
        className={cx('backdrop', {
          'is-active': isFramesOpen,
        })}
        onClick={() => toggleFrames(false)}
      />
    </>
  )
}

export default Frames
