import React from 'react'
import cx from 'classnames'

import { useExchangePrice } from '@lib/context'

const ProductPrice = ({ item, prefix, comparePrice, quantity }) => {
  const exchangePrice = useExchangePrice()

  return (
    <span className={cx('price', { 'is-old': comparePrice })}>
      {prefix}
      {exchangePrice({ item, comparePrice, quantity })}
    </span>
  )
}

export default ProductPrice
